<template>
<div>
  <div class="row justify-content-center" v-if="this.$store.state.versusInviteStatus">
    <div class="col col-sm-10 box_a notification_box">
      <div class="row box_title">
        <div class="col text-center">
          <p class="notifcation_text">Versus</p>
        </div>
      </div>
      <div class="row">
        <div class="col box_bottom">
          <div class="box_img">
            <div class="cAvatar">
              <img :src="appDomainImg + 'profile/15.' + versusInvite.profile" class="cImage" />
            </div>
          </div>
          <div class="box_name">
            <p id="nameUser" class="notifcation_text"> {{ versusInvite.name }}</p>
          </div>
          <div class="box_button">
            <button class="btn btn-primary" v-on:click="startVersus">Iniciar </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<style scoped>
#notification {
  display: none;
}
.notifcation_text {
  color: #202020;
}
.notification_box {
  background: #ffffff;
  transition-duration: 100ms;
  margin-top: 10px;
  margin-left: 1px;
  margin-right: 1px;
  margin-bottom: 10px;
  border-radius: 5px;
  height: 6.8em;
}
.imgav {
  display: flex;
  align-content: center;
  justify-content: center;
}

.cAvatar {
  position: relative;
  width: 50px;
  height: 50px;
  background-color: white;
  border-radius: 50%;
  overflow: hidden;
}
.cImage {
  position: absolute;
  width: 50px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.box_a {
  margin: 10px;
}
.box_name {
  float: left;
  position: relative;
  margin-left: 30px;
  margin-top: 10px;
}
.box_img {
  float: left;
  position: relative;
}
.box_button {
  float: right;
  position: relative;
  margin-top: 7px;
}
.box_bottom {
  height: 70px;
  margin-left: 30px;
  margin-right: 30px;
  float: right;
  position: relative;
  margin-top: 7px;
}
.box_title {
  margin-bottom: 1px;
  height: 30px;
  margin-top: 6px;
}
</style>

<script>
import { mapState } from "vuex";

export default {
  name: "userSession",
  components: {},
  data() {
    return {
      roundVersus: "",
      userAvatar: "",
      userName: "",
      timerNotification: 20,
      responseTime: 0,
      time: null,
      notificationStatusLocal:true,
    };
  },
  created() {
    this.versusChannelPrivate();
  },
  methods: {
    versusChannelPrivate() {
      window.Echo.channel("versus." + this.student.id).listen("InviteChanged", (e) => {
        this.$store.state.versusInvite.id = e.roundVersus;
        this.$store.state.versusInvite.name = e.userName;
        this.$store.state.versusInvite.profile = e.userAvatar;
        this.$store.state.versusInviteStatus = true;
      });
    },
    startVersus(e) {
      e.preventDefault();
      console.log(this.versusInviteStatus);
      this.$router.push({
        name: "Versus",
        params: {
          url: this.$store.state.versusInvite.id,
        },
      });
      this.$store.state.versusInviteStatus = false;
    },
  },
  computed: {
    ...mapState([
      "student",
      "versusInvite",
      "versusInviteStatus",
      ]),
  },
};
</script>
