<template>
  <div>
    <div v-if="loadingNavigation">
      <loadingNav />
    </div>
    <div class="row" v-else v-for="nav in navigation" :key="nav.id" v-on:click="closeMenu">
      <router-link :to="nav.route">{{ nav.name }}</router-link>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import loadingNav from "@/views/student/constants/loading/navigation.vue";

export default {
  name: "navigation",
  components: {
    loadingNav,
  },
  data() {
    return {
    };
  },
  created() {
    this.$store.dispatch("setStudent");
  },
  methods: {
    closeMenu() {
      console.log("remove class");
      var header = document.querySelector(".header");
      var menu = document.querySelector(".menu");
      var sup = document.querySelector('.super_container_inner');
      header.classList.remove("active");
      menu.classList.remove("active");
      sup.classList.remove("active");
    },
  },
  computed: {
    ...mapState(["loadingNavigation", "student", "navigation"]),
  },
};
</script>

<style scoped>
a,
a:hover,
a:visited,
a:active,
a:link {
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    -webkit-text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
    text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
}
</style>
