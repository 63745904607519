import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

import logout from '../components/web/session/logout.vue'

Vue.use(VueRouter)

const routes = [
  { path: '*', redirect: '/'  },
  { path: '/', name: 'Home', 
    component: () => import(/* webpackChunkName: "home" */ '../views/web/landing/index.vue')
  },
  { path: '/contactanos', name: 'Contact', 
    component: () => import(/* webpackChunkName: "contact" */ '../views/web/contact/index.vue') 
  },
  
  //----- Session -----
  { path: '/login', name: 'login', meta: { title: 'Dashboard555' }, 
    component: () => import(/* webpackChunkName: "login" */ '../views/session/login.vue') 
  },
  { path: '/logout', name: 'logout', component: logout,  meta: { requiresAuth: true} },
  { path: '/register', name: 'Register', 
    component: () => import(/* webpackChunkName: "register" */ '../views/session/register.vue') 
  },
  { path: '/verify-email', name: 'VerifyEmail', 
    component: () => import(/* webpackChunkName: "verifyemail" */ '../components/web/session/verify/index.vue')
  },
  { path: '/reset-password', name: 'ResetPassword', 
    component: () => import(/* webpackChunkName: "resetpassword" */ '../components/web/session/password/index.vue')
  },
  { path: '/password/reset/:url', name: 'ResetPasswordNew', 
    component: () => import(/* webpackChunkName: "resetpasswordnew" */ '../components/web/session/password/show.vue')
  },
  
  //----- Courses -----
  { path: '/cursos', name: 'Courses', meta:{ requiresAuth: true },
    component: () => import(/* webpackChunkName: "courses" */ '../views/student/courses/CoursesIndex.vue')
  },
  { path: '/:url/temas', name: 'themas', meta: {requiresAuth: true},
    component: () => import(/* webpackChunkName: "themas" */ '../components/student/courses/show.vue')
  },

  //----- Invite -----
  { name: 'Invite', path: '/invite/:url',
    component: () => import(/* webpackChunkName: "invite" */ '../views/student/invite/index.vue')
  },

  //----- Round -----
  { name: 'Round', path: '/round/:url', meta:{requiresAuth: true}, 
    component: () => import(/* webpackChunkName: "round" */ '../views/student/round/index.vue')
  },
  
  //----- Versus -----
  { name: 'Versus', path: '/versus/:url', meta:{requiresAuth: true},
    component: () => import(/* webpackChunkName: "versus" */ '../views/student/versus/index.vue')
  },

  //----- Student -----,
  { name: 'Profile', path: '/perfil', meta:{requiresAuth:true},
    component: () => import(/* webpackChunkName: "profile" */ '../views/student/profile/index.vue')
  },
  //----- Ranking -----
  { name: 'Ranking', path: '/ranking', meta:{requiresAuth:true},
    component: () => import(/* webpackChunkName: "ranking" */ '../views/student/ranking/index.vue')
  },
  //----- History -----
  { name: 'history', path: '/historial', meta:{requiresAuth:true},
    component: () => import(/* webpackChunkName: "history" */ '../views/student/history/index.vue')
  },
  //----- Friend -----
  { name: 'friends', path: '/friends', meta:{requiresAuth:true},
    component: () => import(/* webpackChunkName: "friends" */ '../views/student/friends/index.vue')
  },
  //----- favorite -----
  { name: 'Favorites', path: '/favoritos', meta:{requiresAuth:true}, 
    component: () => import(/* webpackChunkName: "favorites" */ '../views/student/favorite/index.vue')
  },
  { name: 'favoriteShow', path: '/favoritos/:url', meta:{requiresAuth:true}, 
    component: () => import(/* webpackChunkName: "favoritesshow" */ '../views/student/favorite/show.vue')
  },
  { path: '/404', name: 'Error404',
    component: () => import(/* webpackChunkName: "error404" */ '../views/student/constants/errors/404.vue')
  },
  { path: '/403', name: 'Error403',
    component: () => import(/* webpackChunkName: "error403" */ '../views/student/constants/errors/403.vue')
  },
  { path: '/500', name: 'Error500',
    component: () => import(/* webpackChunkName: "error500" */ '../views/student/constants/errors/500.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.loggedIn) {
      next({
        name: 'login',
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
