<template>
  <div>
    <div class="menu">
      <div class="menu_container text-right">
        <div class="menu_close" v-on:click="close">Cerrar</div>

        <div class="container">
          <div v-if="loggedIn">
            <div class="row justify-content-center">
              <div class="imgav">
                <div class="cAvatar">
                  <img :src="appDomainImg + 'profile/15.' + student.user.avatar" class="cImage"/>
                </div>
              </div>
            </div>
            <div class="row justify-content-center text-center">
              <div class="col-" style="padding-top: 15px">
                <h3>{{ student.user.name }}</h3>
                <p class="font-weight-light" style="display: none">{{ student.user.email }}</p>
              </div>
              <div class="w-100"></div>
              <div class="col-">
                <score-student></score-student>
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <nav class="menu_nav text-left">
            <ul>
              <div v-if="loggedIn">
                <Navigation />
              </div>
            </ul>
          </nav>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { mapGetters } from "vuex";
import Navigation from "@/components/layouts/sidebar/navigation.vue";
import scoreStudent from "@/components/student/score/scoreStudent.vue";

export default {
  name: "sidebarMenu",
  components: {
    Navigation,
    scoreStudent,
  },
  created() {
    this.$store.dispatch('setStudent');
  },
  methods: {
    close() {
      console.log("button close");
      var menu = document.querySelector(".menu");
      var sup = document.querySelector('.super_container_inner');
      menu.classList.remove("active");
      sup.classList.remove("active");
    },
  },
  computed: {
    ...mapState([
      "student",
    ]),
    ...mapGetters(["loggedIn"]),
  },
};
</script>

<style scope>
.imgav {
  display: flex;
  align-content: center;
  justify-content: center;
}
.cAvatar {
  position: relative;
  width: 75px;
  height: 75px;
  background-color: white;
  border-radius: 50%;
  overflow: hidden;
}
.cImage {
  position: absolute;
  width: 75px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
/*********************************
4. Menu
*********************************/

.menu {
  position: fixed;
  top: 0;
  right: -350px;
  background: #ffffff;
  width: 350px;
  height: 100vh;
  z-index: 101;
  -webkit-transition: all 400ms ease;
  -moz-transition: all 400ms ease;
  -ms-transition: all 400ms ease;
  -o-transition: all 400ms ease;
  transition: all 400ms ease;
}
.menu.active {
  right: 0;
}
.menu_container {
  padding-top: 25px;
  padding-right: 30px;
  padding-left: 20px;
  width: 100%;
  height: 100%;
}
.menu_close {
  display: inline-block;
  text-align: right;
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  color: #000000;
  cursor: pointer;
}
.menu_close:hover {
  color: #ffb400;
}
.menu_nav {
  margin-top: 15px;
}
.menu_nav ul li:not(:last-of-type) {
  margin-bottom: 1px;
}
.menu_nav ul li a {
  font-size: 18px;
  color: #000000;
  padding: 5px;
}
.menu_link {
  /*display: none;*/
  position: absolute;
  bottom: 20px;
  right: 30px;
}
.menu_link a {
  font-size: 14px;
  font-weight: 500;
  color: #ffb400;
}
</style>
