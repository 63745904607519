import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import Echo from 'laravel-echo'
import Vuelidate from 'vuelidate'

//domain
Vue.prototype.appDomainFront = 'https://dabuho.com/'
Vue.prototype.appDomain = 'https://api.dabuho.com/'
Vue.prototype.appDomainApi1 = 'https://api.dabuho.com/api/v1/'
Vue.prototype.appDomainImg = 'https://api.dabuho.com/storage/img/'
//Vue.prototype.appDomainFrontImg = 'https://dabuho.com/public/img/'

Vue.use(VueAxios, axios)
Vue.use(Vuelidate)

Vue.config.productionTip = false

//Font
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'

window.Pusher = require('pusher-js');

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: 'ASDASD2121',
    cluster: 'mt1',
    wsHost: 'api.dabuho.com',
    wsPort: 443,
    disableStats: true,
    encrypted: true,
    forceTLS: false,
    enabledTransports: ['ws', 'wss'],
    disabledTransports: ['sockjs', 'xhr_polling', 'xhr_streaming']
});

axios.interceptors.request.use(function(config) {
  const access_token = window.localStorage.getItem('access_token');
  let store = JSON.parse(access_token);
  if(store) {
    config.headers = {
      'Accept': 'application/json',
      'Authorization': `${store["token_type"]} ${store["access_token"]}`,
      };
  } 
  return config;
}, function(err) {
  return Promise.reject(err);
});

axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if ( error.response.status === 401 ){
    const access_token = `${window.localStorage.getItem('access_token')}`
    let store = JSON.parse(access_token);
    if(store){
      const url = "https://api.dabuho.com/api/v1/refreshtoken";
      axios
        .post(url, 
          { refreshtoken: `${store["refresh_token"]}` }
        )
        .then(function(response) {
            const token = response.data;
            localStorage.setItem('access_token', JSON.stringify(token));
            //store.state.loggedIn = true;
            router.go(0);
          });
    }
  }
  if ( error.response.status === 500 ){
    router.push({ name: 'Error500' })
  }
  if ( error.response.status === 403 ){
    router.push({ name: 'Error403' })
  }
  if ( error.response.status === 404 ){
    router.push({ name: 'Error404' })
  }
  return Promise.reject(error);
});


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
