<template>
  <div id="app">
    <sidebarMenu />
    <div class="super_container">
      <div class="super_container_inner">
        <div class="super_overlay" v-on:click="superOverlay"></div>
        <div v-if="$route.name === 'Round' || $route.name === 'Versus'">
          <userSession />
          <router-view />
        </div>
        <div v-else-if="$route.name === 'Home'">
          <headerIndex />
          <userSession />
          <router-view />
        </div>
        <div v-else-if="$route.name === 'Invite'">
          <router-view />
        </div>
        <div v-else>
          <headerIndex />
          <div class="container view_content">
            <router-view />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import sidebarMenu from "@/components/layouts/sidebar/menu.vue";
import headerIndex from "@/components/layouts/header/header.vue";
import userSession from "@/components/student/notifications/userSession/index.vue";

export default {
  name: "appIndex",
  components: {
    sidebarMenu,
    headerIndex,
    userSession,
  },
  methods: {
    superOverlay() {
      var menu = document.querySelector(".menu");
      var sup = document.querySelector(".super_container_inner");
      menu.classList.remove("active");
      sup.classList.remove("active");
    },
  },
  computed: {
    showViewHome() {
      return this.$route.name === "Home";
    },
  },
};
</script>

<style>
@font-face {
  font-family: "Ubuntu";
  src: local("Ubuntu"), url(/fonts/Ubuntu-Regular.ttf) format("truetype");
}
a,
a:hover,
a:visited,
a:active,
a:link {
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -webkit-text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
/*
.view_content{
    height: 100vh;
}*/
/*********************************

*********************************/

.super_container {
  width: 100%;
  overflow: hidden;
}

.super_container_inner {
  -webkit-transition: all 400ms ease;
  -moz-transition: all 400ms ease;
  -ms-transition: all 400ms ease;
  -o-transition: all 400ms ease;
  transition: all 400ms ease;
}

.super_container_inner.active {
  -webkit-transform: translateX(-350px);
  -moz-transform: translateX(-350px);
  -ms-transform: translateX(-350px);
  -o-transform: translateX(-350px);
  transform: translateX(-350px);
}

.super_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background: rgba(255, 255, 255, 0.85);
  z-index: 1001;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 400ms ease;
  -moz-transition: all 400ms ease;
  -ms-transition: all 400ms ease;
  -o-transition: all 400ms ease;
  transition: all 400ms ease;
}

.super_container_inner.active .super_overlay {
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
}

/*********************************
5. Home
*********************************/

ul {
  list-style: none;
  margin-bottom: 0px;
}

.home {
  height: 899px;
  overflow: hidden;
}

.home_slider_container {
  width: 100%;
  height: 100%;
}

.home_slider_dots {
  position: absolute;
  top: 44%;
  left: 53px;
  z-index: 1;
}

.home_slider .owl-dots {
  display: none;
}

.home_slider_custom_dot {
  position: relative;
  width: 100%;
  text-align: center;
  background: transparent;
  line-height: 35px;
  font-size: 16px;
  font-weight: 300;
  color: #ffffff;
  cursor: pointer;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}

.home_slider_custom_dot:hover,
.home_slider_custom_dot.active {
  color: #ffb400;
}

.home_slider_custom_dot:not(:last-child) {
  margin-bottom: 15px;
}

.home_container {
  position: absolute;
  left: 0;
  top: 36.2%;
  width: 100%;
}

.home_title {
  display: inline-block;
}

.home_title h1 {
  color: #ffffff;
  font-size: 72px;
  font-weight: 400;
  line-height: 1.2;
}

.home_title h1 span {
  position: relative;
  line-height: 1.2;
}

.home_title h1 span::after {
  display: block;
  position: absolute;
  top: 22px;
  right: -8px;
  width: 27px;
  height: 27px;
  border-radius: 50%;
  background: #0077ff;
  line-height: 27px;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  content: "+";
}
</style>
