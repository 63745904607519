import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router/index'
import axios from 'axios'

Vue.use(Vuex, router, axios)

export default new Vuex.Store({
  state: {
    course: '',
    courses: [{
      id: 0,
      name: 'Curso',
      slug: 'curso',
      avatar: 'default.jpg'
    }],
    themas: [{
      id: 0,
      name: 'Tema'
    }],
    student: 
    {
      id: 0,
      user:{
        avatar: "default.jpg"
      }
    },
    round: [],
    viewFavorites: [],
    favorites: [],
    pageFavorites:'',
    favoriteId: [],
    loadingCourses: true,
    loadingThemas: true,
    loadingFavorites: false,
    loadingNavigation: true,
    score:'',
    currentQuiz:'',
    block:[],
    blocks:[],
    navigation:[],
    linkVersus:'',
    linkVersusStatus:false,
    token: localStorage.getItem('access_token') || null,
    refresh: localStorage.getItem('refresh_token') || null,
    expires: null,
    versusInvite: {
      id: 0,
      name: 'Amigo',
      profile: 'default.jpg'
    },
    versusInviteStatus:false,
  },
  getters: {
    loggedIn (state) {
      return state.token !== null
    }
  },
  mutations: {
    setCourses (state, courses) {
      state.courses = courses
    },
    setThemas (state, themas) {
      state.themas = themas
    },
    retrieveToken (state, token) {
      state.token = token
    },
    destroyToken (state) {
      state.token = null
    },
    refreshToken (state, refresh) {
      state.refresh = refresh
    },
    setStudent (state, student) {
      state.student = student
    },
    setBlocks (state, blocks) {
      state.blocks = blocks
    },
    setBlock (state, block) {
      state.block = block
    },
    setNavigation (state, navigation) {
      state.navigation = navigation
    },
    setRound (state, round) {
      state.round = round
    },
    setRegister (state, token) {
      state.token = token
    },
    setViewFavorites (state, viewFavorites) {
      state.viewFavorites = viewFavorites
    },
    setFavorites (state, favorites) {
      state.favorites = favorites
    },
    setFavoriteId (state, favoriteId) {
      state.favoriteId = favoriteId
    },
    setScore (state, score) {
      state.score = score
    },
    setThemasVersus (state, themas) {
      state.themas = themas
    },
    setLinkVersus (state, linkVersus) {
      state.linkVersus = linkVersus
    },
    setVersusInviteStatus (state, versusInviteStatus) {
      state.versusInviteStatus = versusInviteStatus
    },
  },
  actions: {
    async setCourses ({ commit }) {
      this.state.loadingCourses = true
      const currentName = this.state.block.block.slug
      const currentStorage = window.localStorage.getItem(currentName)
      if (currentStorage) {
        commit('setCourses', JSON.parse(currentStorage))
        this.state.loadingCourses = false
      } else {
        try {
          const url = 'https://api.dabuho.com/api/v1/courses/data'
          const token = `${window.localStorage.getItem('access_token')}`
          let store = JSON.parse(token);
          await fetch(url, { 
            headers: {
              Accept: 'application/json',
              Authorization: `${store["token_type"]} ${store["access_token"]}` 
            }
          })
            .then(data => data.json())
            .then(data => {
              if (data.code == 401) {
                console.log("401");
                console.log("resetToken");
                this.dispatch('resetToken');
              } else {
                commit('setCourses', data);
                window.localStorage.setItem(currentName, JSON.stringify(data));
                this.state.loadingCourses = false;
              }
            })
        } catch (error) {
          console.error(error)
        }
      }
    },
    async setThemas ({ commit }) {
      this.state.loadingThemas = true
      const currentThema = router.currentRoute.params.url;
      const themas = window.localStorage.getItem(currentThema)
      if (themas) {
        commit('setThemas', JSON.parse(themas))
        this.state.loadingThemas = false
      } else {
        try {
          this.state.loadingThemas = true
          const url = 'https://api.dabuho.com/api/v1/courses/' + currentThema + '/themes'
          const token = `${window.localStorage.getItem('access_token')}`
          let store = JSON.parse(token);
          await fetch(url, { 
            headers: { 
              Accept: 'application/json',
              Authorization: `${store["token_type"]} ${store["access_token"]}` 
            }
          })
            .then(data => data.json())
            .then(data => {
              if (data.code == 401) {
                console.log("401");
                console.log("resetToken");
                this.dispatch('resetToken');
              } else {
                commit('setThemas', data)
                window.localStorage.setItem(currentThema, JSON.stringify(data))
                this.state.loadingThemas = false
              }
            })
        } catch (error) {
          console.error(error)
        }
      }
    },
    async setThemasVersus ({ commit }) {
      const currentThema = this.state.course
      this.state.loadingThemas = true
      const themas = window.localStorage.getItem(currentThema)
      if (themas) {
        commit('setThemas', JSON.parse(themas))
        this.state.loadingThemas = false
      } else {
        const url = "https://api.dabuho.com/api/v1/courses/" + this.state.course + "/themes";
        axios
          .get(url, { headers: { Authorization: "Bearer " + this.state.token } })
          .then(function(response) {
              if (response.data.length) {
                  commit('setThemasVersus', response.data)
                  window.localStorage.setItem(currentThema, JSON.stringify(response.data))
              } else {
                const abc = '[{ "id": 0, "name": "Sin temas disponilbes."}]';
                commit('setThemasVersus', JSON.parse(abc));
              }

          })
      }
    },
    async setStudent ({ commit }) {
      const currentStudent = "student";
      const student = window.localStorage.getItem(currentStudent)
      if (student) {
        commit('setStudent', JSON.parse(student))
      } else {
        try {
          const url = 'https://api.dabuho.com/api/v1/profile/data';
          const token = `${window.localStorage.getItem('access_token')}`;
          let store = JSON.parse(token);
          await fetch(url, {
            headers: {
              Accept: 'application/json',
              Authorization: `${store["token_type"]} ${store["access_token"]}` 
            }
          })
            .then(data => data.json())
            .then(data => {
              if (data.code == 401) {
                console.log("401");
                console.log("resetToken");
                this.dispatch('resetToken');
              } else {
                console.log('setStudent else')
                commit('setStudent', data)
                window.localStorage.setItem(currentStudent, JSON.stringify(data))
              }
            })
        } catch (error) {
          console.log(error);
        }
      }
    },
    setBlocks ({ commit }) {
      const current = 'blocks';
      const currentStorage = window.localStorage.getItem(current)
      if (currentStorage) {
        commit('setBlocks', JSON.parse(currentStorage))
      } else {
        try {
          const url = 'https://api.dabuho.com/api/v1/profile/getblocks';
          const token = `${window.localStorage.getItem('access_token')}`;
          let store = JSON.parse(token);
          fetch(url, { 
            headers: { 
              Accept: 'application/json',
              Authorization: `${store["token_type"]} ${store["access_token"]}` 
            }
          })
            .then(data => data.json())
            .then(data => {
              if (data.code == 401) {
                console.log("401");
                console.log("resetToken");
                this.dispatch('resetToken');
              } else {
                commit('setBlocks', data)
                window.localStorage.setItem(current, JSON.stringify(data))
              }
            })
        } catch (error) {
          console.error(error)
        }
      }
    },
    async setBlock ({ commit }) {
      const current = 'block'
      const currentStorage = window.localStorage.getItem(current)
      if (currentStorage) {
        commit('setBlock', JSON.parse(currentStorage))
      } else {
        try {
          const url = 'https://api.dabuho.com/api/v1/profile/block'
          const token = `${window.localStorage.getItem('access_token')}`
          let store = JSON.parse(token);

          await fetch(url, { 
            headers: { 
              Accept: 'application/json',
              Authorization: `${store["token_type"]} ${store["access_token"]}` 
            }
            })
            .then(data => data.json())
            .then(data => {
              if (data.code == 401) {
                console.log("401");
                console.log("resetToken");
                this.dispatch('resetToken');
              } else {
                console.log("data setBlock");
                commit('setBlock', data);
                window.localStorage.setItem(current, JSON.stringify(data));
                this.state.loadingCourses = false;
              }
            })
        } catch (error) {
          console.error(error)
        }
      }
    },
    async setNavigation ({ commit }) {
      this.state.loadingNavigation = true;
      const current = 'navigation';
      const currentStorage = window.localStorage.getItem(current);
      if (currentStorage) {
        commit('setNavigation', JSON.parse(currentStorage));
        this.state.loadingNavigation = false;
      } else {
        try {
          const url = 'https://api.dabuho.com/api/v1/profile/navigation';
          const token = `${window.localStorage.getItem('access_token')}`;
          let store = JSON.parse(token);
          await fetch(url, { 
            headers: { 
              Accept: 'application/json',
              Authorization: `${store["token_type"]} ${store["access_token"]}` 
            }
            })
            .then(data => data.json())
            .then(data => {
              if (data.code == 401) {
                console.log("401");
                console.log("resetToken");
                this.dispatch('resetToken');
              } else {
                commit('setNavigation', data);
                window.localStorage.setItem(current, JSON.stringify(data));
                this.state.loadingNavigation = false;
              }
            })
        } catch (error) {
          console.error(error)
        }
      }
    },
    retrieveToken (context, credentials) {
      return new Promise((resolve, reject) => {
        axios.post('https://api.dabuho.com/api/v1/login', 
        {
          email: credentials.email,
          password: credentials.password,
        }
        )
          .then(response => {
            if( response.data.expires_in ){
              const token = response.data;
              window.localStorage.setItem('access_token', JSON.stringify(token));
              context.commit('retrieveToken', token);
              context.state.loggedIn = true;
              resolve(response)
            } else {
              resolve(response)
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    setRegister (context, credentials) {
      return new Promise((resolve, reject) => {
        axios.post('https://api.dabuho.com/api/v1/register', {
          name: credentials.name,
          email: credentials.email,
          password: credentials.password,
        },
        {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        })
          .then(response => {
            console.log("response.data")
            const token = JSON.stringify(response.data)
            window.localStorage.setItem('access_token', token)
            context.commit('setRegister', token);
            resolve(response);
            context.state.loggedIn = true;
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    destroyToken (context) {
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          axios.post('https://api.dabuho.com/api/v1/logout', '', {
            headers: { Authorization: 'Bearer ' + context.state.token }
          })
            .then(response => {
              localStorage.removeItem('access_token')
              localStorage.removeItem('student')
              localStorage.removeItem('dataScoreStudent')
              localStorage.removeItem('navigation')
              context.commit('destroyToken')
              resolve(response)
              context.state.loggedIn = false
            })
            .catch(error => {
              localStorage.removeItem('access_token')
              context.commit('destroyToken')
              reject(error)
            })
        })
      }
    },
    async resetToken (context) {
      /*
      axios.post('/resetToken', {refreshtoken: this.expires})
       .then(({data}) => {
          console.log(this.expires)
          this.show = false;
          window.axios.defaults.headers.common['X-CSRF-TOKEN'] = data;
       })
       .catch((error) => {
           this.handle(error)
       });
       */

      const access_token = `${window.localStorage.getItem('access_token')}`
      let store = JSON.parse(access_token);
      if(store){
        try {
          const url = "https://api.dabuho.com/api/v1/refreshtoken";
          axios
            .post(url, { 
                Accept: 'application/json',
                refreshtoken: `${store["refresh_token"]}` 
            })
            .then(function(response) {
                const token = response.data;
                localStorage.setItem('access_token', JSON.stringify(token));
                context.state.loggedIn = true;
                console.log("route go");
                router.go(0);
              });
        } catch (error) {
          console.error(error)
        }
      }
    },
    async setRound ({ commit }) {
      const dataRound = 'userdata'
      const userRound = window.localStorage.getItem(dataRound)
      if (userRound) {
        commit('setRound', JSON.parse(userRound))
      } else {
        try {
          const url = 'https://api.dabuho.com/api/v1/usuario2222'
          console.log(url)
          await fetch(url)
            .then(data => data.json())
            .then(data => {
              commit('setRound', data)
              window.localStorage.setItem(
                dataRound, JSON.stringify(data)
              )
            })
        } catch (error) {
          console.error(error)
        }
      }
    },
    async setViewFavorites ({ commit }) {
      const currentFavorite = router.currentRoute.params.url;
      const dataStorage = window.localStorage.getItem(currentFavorite);
      if (dataStorage) {
        commit('setFavoriteId', JSON.parse(dataStorage))
      } else {
        try {
          const url = 'https://api.dabuho.com/api/v1/viewFavorite/' + currentFavorite;
          const access_token = `${window.localStorage.getItem('access_token')}`;
          let store = JSON.parse(access_token);
          await fetch(url, 
            { headers: { Authorization: `${store["token_type"]} ${store["access_token"]}` }} )
            .then(data => data.json())
            .then(data => {
              if (data.getFavorite) {
                commit('setFavoriteId', data)
                window.localStorage.setItem(currentFavorite, JSON.stringify(data))
              } else {
                router.push({ name: 'Error403' })
              }
            })
        } catch (error) {
          console.error(error)
        }
      }
    },
    async setFavoriteId ({ commit }) {
      const currentFavorite = router.currentRoute.params.url;
      const dataStorage = window.localStorage.getItem(currentFavorite);
      if (dataStorage) {
        commit('setFavoriteId', JSON.parse(dataStorage))
      } else {
        try {
          const url = 'https://api.dabuho.com/api/v1/viewFavorite/' + currentFavorite;
          const access_token = `${window.localStorage.getItem('access_token')}`;
          let store = JSON.parse(access_token);
          await fetch(url, 
            { headers: { Authorization: `${store["token_type"]} ${store["access_token"]}` }} )
            .then(data => data.json())
            .then(data => {
              if (data.code != 403) {
                commit('setFavoriteId', data)
                window.localStorage.setItem(currentFavorite, JSON.stringify(data))
              } else {
                router.push({ name: 'Error403' })
              }
            })
        } catch (error) {
          console.error(error)
        }
      }
    },
    async setFavorites ({ commit }) {
      const nameData = "dataFavorite01"
      const url = 'https://api.dabuho.com/api/v1/allfavorites?page=' + this.page
      const dataStorage = window.localStorage.getItem(nameData)
      const access_token = `${window.localStorage.getItem('access_token')}`
      let store = JSON.parse(access_token);

      if (dataStorage) {
          commit('setFavorites', JSON.parse(dataStorage))
      } else {
          try {
              await fetch(url, { headers: { Authorization: `${store["token_type"]} ${store["access_token"]}` }})
              .then(data => data.json())
              .then(data => {
                  if(this.page ==1){
                      this.favorites.push(...data.allFavorites)
                  }
                  if(this.page != 1){
                      this.favorites = this.favorites.concat(data.allFavorites.data);
                      window.localStorage.setItem(nameData, JSON.stringify(this.favorites))
                  }else{
                      window.localStorage.setItem(nameData, JSON.stringify(data.allFavorites.data))
                  }
                  this.lastPage = data.allFavorites.last_page
              })
          } catch (error) {
              console.error(error)
          }
      }
    },
    async setScore ({ commit }) {
      const nameData = "dataScoreStudent";
      try {
        var menu = document.querySelector('.menu');
        var sup = document.querySelector('.super_container_inner');
        menu.classList.add("active");
        sup.classList.add("active");
        const url = 'https://api.dabuho.com/api/v1/scoreStudent';
        const access_token = `${window.localStorage.getItem('access_token')}`;
        let store = JSON.parse(access_token);
        await fetch(url, { 
          headers: { 
            Accept: 'application/json',
            Authorization: `${store["token_type"]} ${store["access_token"]}`,
          }
          })
          .then(data => data.json())
          .then(data => {
            if (data.code == 401) {
              console.log("401");
              console.log("resetToken");
              this.dispatch('resetToken');
            } else {
              commit('setScore', data);
              window.localStorage.setItem(nameData, JSON.stringify(data));
            }

          })
      } catch (error) {
        console.error(error)
      }
    },
    async setVersusInvite (context, versus) {
      
      console.log("setVersusInvite")
      console.log(versus)
      const dataVersus = JSON.stringify(versus)
      console.log(dataVersus)
      this.state.versusInviteStatus = true
      context.commit('versusInvite', dataVersus)

    },
  },
  modules: {}
})
