<template>
<div>
    <div>
        <p class="font-weight-light" style="font-size: 25px;">{{ score.scoreStudent }} EXP</p>       
    </div>
</div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    computed: {
        ...mapState(['score'])
    }
}
</script>